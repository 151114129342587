<template>
  <div>
    <div class="h-100 bg-animation">
      <div class="d-flex h-100 justify-content-center align-items-center">
        <b-col md="8" class="mx-auto app-login-box">
        <apexchart height="350" type="bar" :options="chartOptions" :series="series"></apexchart>
        <div class="text-center">
          <h4 >
            <div>{{pollTitle}}</div>
          </h4>
          <h6 class="mb-3 ml-3 mr3">
            <span >{{pollCount}}</span>
          </h6>
        </div>
        </b-col>
      </div>
    </div>
  </div>
</template>

<script>
import VueElementLoading from "vue-element-loading";
import swal from "sweetalert";
import VueApexCharts from "vue-apexcharts";
import PollsService from "@/services/polls.service";

export default {
  name: 'PollsResultsStandaloneColumn',
  components: {
    VueElementLoading,
    apexchart: VueApexCharts
  },
  props:{
    pollId: {
      type: String,
      default: "0",
    }
  },
  data() {
    return {

        chartOptions: {
          grid:
              {
                show:false
              },
          toolbar:
              {
                show:false
              },
          plotOptions: {
            bar: {
              horizontal: false,
              endingShape: "rounded",
              columnWidth: "55%",
              padding: 10
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 20,
            colors: ["transparent"]
          },

          xaxis: {
            categories: [
              ""
            ]
          },
          yaxis: {
            title: {
              text: "Гласували"
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function(val) {
                if(val == 1)
                {
                  return val + " глас";
                }
                else
                {
                  return val + " гласа";
                }
              }
            }
          }
        },

        series: [],
      showLoading: false,
      pollTitle:"",
      pollCount:""
    };
  },
  computed: {

  },
  created() {
    this.getPollStats();
  },
  mounted () {
    window.setInterval(() => {
      this.getSessionLatestMessages()
    }, 60000)

  },
  methods: {

    getPollStats(pollId)
    {
      this.showLoading = true
      PollsService.getPollStats(this.pollId).then((response) =>
      {
        response

        this.pollTitle = response.data.response.poll_title;
        this.pollCount = "Общо гласували: "+response.data.response.total_answered;
        this.answers = response.data.response.results;
        this.series = []
        for (const answer of this.answers) {
          this.series.push({name:answer.title,data:[answer.count]})
        }

        this.$root.$emit('bv::toggle::modal', 'modal-stats', '#btnToggle')
      })

      this.showLoading = false
    },

    updateChart() {
      const max = 100;
      const min = 20;
      let newSeries = [];

      this.series.map(s => {
        const data = s.data.map(() => {
          return Math.floor(Math.random() * (max - min + 1)) + min;
        });
        newSeries.push({ data });
      });

      this.series = newSeries;
    }
  }
};
</script>